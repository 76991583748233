import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import dayjs, { Dayjs } from "dayjs";
import DateTimePickerModal from "./CustomModal/DateTimePickerModal";
import { getAirports } from "../service/UserApi";
import { Airport } from "../types/types";
import FilterCheckBoxes from "./FilterCheckBoxes";
import { GlobalContext } from "../globalContext";
import reservationActions from "../actions/ReservationActions";
import { DATE_FORMAT, getDaysBetweenDates } from "../../utils/utils.ts";
import FilterDropdown from "../components/FilterDropdown";

import DatePicker from "react-datepicker";

import "../../node_modules/react-datepicker/dist/react-datepicker.css";
import Spinner from "../components/Spinner";

type SearchBoxParams = {
  page: 'inner' | 'outer'
  onAirportSelected?: (airport: Airport) => void
  onDateChanged?: () => void
  onFilters?: (filters: string[]) => void
  disable_airport?: boolean
  onBackPress?: () => void

}
const filters = [
  "Covered Self Park",
  "Uncovered Self Park",
  "Covered Valet",
  "Uncovered Valet",
];

const SearchBox = ({ page, onAirportSelected, onDateChanged, onFilters, disable_airport, onBackPress }: SearchBoxParams) => {
  const [airports, setAirports] = useState([]);
  const [airportsData, setAirportsData] = useState<Airport[]>([]);
  const [selectedAirport, setSelectedAirport] = useState(null);
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const [showDataPicker, setShowDatePicker] = useState(false);
  const [timePikerType, setTimePickerType] = useState<'checkin' | 'checkout' | ''>('');

  const [checkInDate, setCheckInDate] = useState<Dayjs | null>(null);
  const [checkoutDate, setCheckoutData] = useState<Dayjs | null>(null);
  const [filterDrop, setFilterDrop] = useState(false);
  const [loadAirport, setLoadAirport] = useState(false);
  const { dispatch } = React.useContext(GlobalContext);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const checkIn = urlParams.get('checkIn');
  const checkOut = urlParams.get('checkOut');
  const airportId = urlParams.get('airport_id');
  const [selectedFilters, setSelectedFilters] = useState([]);


  useEffect(() => {
    // Fetch airports data from the API
    const fetchAirports = async () => {
      setLoadAirport(true)
      try {
        const response: Airport[] = await getAirports();
        const airportOptions = response.map((airport) => ({
          value: airport.id,
          label: `${airport.name} (${airport.code})`,
        }));

        airportOptions.sort((a, b) => a?.label?.localeCompare(b?.label));
        setAirports(airportOptions);
        setAirportsData(response);
        setLoadAirport(false)

        // Select the airport based on airportId from URL params
        if (airportId) {
          const selected = airportOptions.find(airport => airport.value == airportId);
          setSelectedAirport(selected);

        }
      } catch (error) {
        console.error("Error fetching airports data:", error);
      }
    };

    fetchAirports();
  }, [airportId]); // Run useEffect whenever airportId changes
  // useEffect(() => {
  //   // Sort airports alphabetically by label

  // }, [airports]);
  const handleSearchClick = () => {
    if (selectedAirport && checkInDate && checkoutDate) {
      const urlParams = buildUrlParams();
      if (!disable_airport) navigate(`/search?${urlParams}`);
    } else {
      // alert('Please select an airport and valid check-in and check-out dates.');
    }
  };
  useEffect(() => {
    if (checkIn && checkOut) {
      const checkInDateTime = dayjs(checkIn, 'YYYY-MM-DD hh:mm A');
      const checkOutDateTime = dayjs(checkOut, 'YYYY-MM-DD hh:mm A');
      setCheckInDate(checkInDateTime);
      setCheckoutData(checkOutDateTime);
      dispatch(reservationActions.updateCheckin(checkInDateTime.format(DATE_FORMAT)))
      dispatch(reservationActions.updateCheckout(checkOutDateTime.format(DATE_FORMAT)))
      dispatch(reservationActions.updateDays(getDaysBetweenDates(checkIn, checkOut)))
      if (page == 'inner') {
        handleSearchClick()
      }
      onDateChanged && onDateChanged()
    }
  }, [checkIn, checkOut]);

  const enableNavigate = () => {
    return checkIn && checkOut && airportId
  }
  const CheckInComponent = () => (
    <div
    onClick={() => {
      setShowDatePicker(true)
      setTimePickerType('checkin')
    }}
    className={`${timePikerType == 'checkin' ? 'hasSelect' : ''} px-2 py-1 rounded-md transition-all`}>
      <p>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        Check in
      </p>
      <p className={`text-[#2B2B2B] text-[16px] md:w-[200px] w-full check-date-text  `} style={{ color: '#2B2B2B !important', backgroundColor: timePikerType == 'checkin' ? '' : undefined }}
    >
        {checkInDate?.format(DATE_FORMAT) ?? 'Select Check-in'}
      </p>
    </div>
  );

  const ChecoutComponent = () => (
    <div
    onClick={() => {

      setTimePickerType('checkout')
      setShowDatePicker(true)
    }}
    className={`${timePikerType == 'checkout' ? 'hasSelect' : ''} px-2 py-1 rounded-md transition-all`}>
      <p>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12.6667 2.66663H3.33333C2.59695 2.66663 2 3.26358 2 3.99996V13.3333C2 14.0697 2.59695 14.6666 3.33333 14.6666H12.6667C13.403 14.6666 14 14.0697 14 13.3333V3.99996C14 3.26358 13.403 2.66663 12.6667 2.66663Z" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M10.6641 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M5.33594 1.33337V4.00004" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M2 6.66663H14" stroke="#2EABE3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

        Check out
      </p>
      <p className={` text-[#2B2B2B]  text-[16px] md:w-[200px] w-full check-date-text  `} style={{ color: '#2B2B2B !important', backgroundColor: timePikerType == 'checkout' ? '' : undefined }} 
      >
        {checkoutDate?.format(DATE_FORMAT) ?? 'Select Check-out'}
      </p>
    </div>
  );
  useEffect(() => {
    if (onAirportSelected && selectedAirport) {
      const tempAirport = airportsData.find(it => it.id == selectedAirport.value)
      if (tempAirport) {
        dispatch(reservationActions.updateAirPort(tempAirport))
        onAirportSelected(tempAirport)
      }
    }


  }, [selectedAirport])

  const buildUrlParams = () => {
    const params = new URLSearchParams();
    if (selectedAirport) params.set('airport_id', selectedAirport?.value);
    if (checkInDate) params.set('checkIn', checkInDate.format('YYYY-MM-DD hh:mm A'));
    if (checkoutDate) params.set('checkOut', checkoutDate.format('YYYY-MM-DD hh:mm A'));
    return params.toString();
  };

  const handleClear = () => {
    onFilters([])
    setSelectedFilters([]);
    console.log('Clear filters');
  };

  const handleApply = (selectedFilters) => {
    console.log('Selected filters:', selectedFilters);
    setSelectedFilters(selectedFilters);
    onFilters && onFilters(selectedFilters)

  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,

    });
  };


  return (
    <>




      {/* desktop view for home and search page */}
      <div className="lg:block hidden relative">

        <div className={`search-field-holder ${page === "inner" ? "inner-search container-fluid mx-auto px-10" : ""}`}>
          {page === "inner" && !disable_airport && (
            <FilterDropdown selectedFilters={selectedFilters} filters={filters} onClear={handleClear} onApply={handleApply} />
          )}
          {page === "inner" && disable_airport && (
            <button onClick={() => {
              onBackPress && onBackPress()
              // navigate('/search?'+buildUrlParams())
            }}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87 7.75082L4.84 9.72082C4.91369 9.78949 4.97279 9.87229 5.01378 9.96428C5.05477 10.0563 5.07681 10.1556 5.07859 10.2563C5.08037 10.357 5.06184 10.457 5.02412 10.5504C4.9864 10.6438 4.93026 10.7286 4.85904 10.7999C4.78782 10.8711 4.70299 10.9272 4.6096 10.9649C4.51621 11.0027 4.41618 11.0212 4.31548 11.0194C4.21477 11.0176 4.11546 10.9956 4.02346 10.9546C3.93146 10.9136 3.84866 10.8545 3.78 10.7808L0.53 7.53082L0 7.00082L0.53 6.47082L3.78 3.22082C3.92217 3.08834 4.11022 3.01622 4.30452 3.01965C4.49882 3.02308 4.68421 3.10179 4.82162 3.2392C4.95903 3.37661 5.03775 3.562 5.04117 3.7563C5.0446 3.9506 4.97248 4.13865 4.84 4.28082L2.87 6.25082H12.75C13.612 6.25082 14.4386 6.59323 15.0481 7.20273C15.6576 7.81222 16 8.63887 16 9.50082C16 10.3628 15.6576 11.1894 15.0481 11.7989C14.4386 12.4084 13.612 12.7508 12.75 12.7508H10.75C10.5511 12.7508 10.3603 12.6718 10.2197 12.5312C10.079 12.3905 10 12.1997 10 12.0008C10 11.8019 10.079 11.6111 10.2197 11.4705C10.3603 11.3298 10.5511 11.2508 10.75 11.2508H12.75C13.2141 11.2508 13.6592 11.0664 13.9874 10.7383C14.3156 10.4101 14.5 9.96495 14.5 9.50082C14.5 9.03669 14.3156 8.59157 13.9874 8.26339C13.6592 7.9352 13.2141 7.75082 12.75 7.75082H2.87Z" fill="white" />
              </svg>
            </button>
          )}
          <div className={`relative w-full select-airport ${!selectedAirport && checkInDate && checkoutDate && !loadAirport ? '  border-[1px] jiggle border-[red]': ''}`}>
            {page === "inner" && <span className="airport-tag">Airport</span>}
            {loadAirport ? <Spinner /> :
              <>
                <Select
                  onInputChange={(query) => {
                    if (query?.length > 0) setShowDropdownMenu(true)
                    else setShowDropdownMenu(false)
                  }}
                  // menuIsOpen={showDropdownMenu}
                  onMenuClose={() => {
                    setShowDropdownMenu(false)
                  }}
                  options={airports}

                  value={selectedAirport}
                  isDisabled={disable_airport}
                  onChange={(selected) => {
                    setSelectedAirport(selected)
                    if (checkInDate == null || checkoutDate == null) {
                      setShowDatePicker(true)
                      setTimePickerType('checkin')
                    }
                    setSelectedFilters([])
                  }}
                  isClearable
                  isSearchable
                  placeholder="Search airport..."
                  className="airport-dropdown"
                />


                {!selectedAirport && checkInDate && checkoutDate && !loadAirport ?
                  <p className="text-[#ee4c4c] text-[12px] please-select">Please select Airport</p>
                  : ''}
              </>
            }


          </div>
          <div className="checkin-box-holder">
            <div className="checkin-box">

              <CheckInComponent />
            </div>
            <div className="checkin-box">

              <ChecoutComponent />
            </div>
          </div>

          <button onClick={handleSearchClick} disabled={!selectedAirport || !checkInDate || !checkoutDate}>
            <span>Search</span>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M20.9984 21L16.6484 16.65" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </button>
        </div>


        {showDataPicker && (

          <DateTimePickerModal
            onClosePaymentModal={() => {
              scrollToTop()
              setShowDatePicker(false)
              setTimePickerType('')
            }}
            setFieldFocus={(fieldName) => {
              setTimePickerType(fieldName)
            }}
            onCheckinSelected={(checkIn) => {
              // alert(checkIn)
              setCheckInDate(dayjs(checkIn, 'YYYY-MM-DD hh:mm A'));
              setTimePickerType('checkout')
            }}
            onCheckoutSelected={(checkout) => {
              // alert(checkIn)
              setCheckoutData(dayjs(checkout, 'YYYY-MM-DD hh:mm A'));
              setTimePickerType('checkout')
            }}
            selectedField={timePikerType}

            onAllSelected={(date) => {
              setTimePickerType('')
              onDateChanged && onDateChanged()
              setCheckInDate(dayjs(date.checkIn, 'YYYY-MM-DD hh:mm A'));
              setCheckoutData(dayjs(date.checkOut, 'YYYY-MM-DD hh:mm A'));
              dispatch(reservationActions.updateCheckin(date.checkIn))
              dispatch(reservationActions.updateCheckout(date.checkOut))
              dispatch(reservationActions.updateDays(getDaysBetweenDates(date.checkIn, date.checkOut)))

            }} />

        )}


      </div>

      {/* mobile version of home and search */}
      <div className="lg:hidden block relative">
        {page === "inner" ? (
          <div className="container mx-auto inner-search-holder bg-[#eeeeee]">
            <div className={`search-field-holder inner-search w-full`}>
              <div className="flex w-full">
                <div className={`flex flex-col w-full select-airport ${!selectedAirport && checkInDate && checkoutDate && !loadAirport ? '  border-[1px] jiggle border-[red]': ''} `}>
                  <div className="relative w-full">
                    <span className="airport-tag">Airport</span>
                    {loadAirport ? <Spinner /> :
                      <>
                        <Select
                          options={airports}
                          onInputChange={(query) => {
                            if (query?.length > 0) setShowDropdownMenu(true)
                            else setShowDropdownMenu(false)
                          }}
                          // menuIsOpen={showDropdownMenu}
                          isDisabled={disable_airport}
                          onMenuClose={() => {
                            setShowDropdownMenu(false)
                          }}
                          value={selectedAirport}
                          onChange={(selected) => setSelectedAirport(selected)}
                          placeholder="Search airport..."
                          className="airport-dropdown"
                        />
                        {!selectedAirport && checkInDate && checkoutDate && !loadAirport ?
                          <p className="text-[#ee4c4c] text-[12px] please-select">Please select Airport</p>
                          : ''}
                      </>
                    }
                  </div>
                  <div className="checkin-box-holder">
                    <div className="checkin-box">

                      <CheckInComponent />
                    </div>
                    <div className="checkin-box">

                      <ChecoutComponent />
                    </div>
                  </div>

                </div>
              </div>
              <div className="flex flex-col w-fit justify-between gap-3">
                <div className="relative  ">
                  {page === "inner" && !disable_airport && (
                    <FilterDropdown selectedFilters={selectedFilters} filters={filters} onClear={handleClear} onApply={handleApply} />
                  )}
                  {page === "inner" && disable_airport && (
                    <button onClick={() => {
                      onBackPress && onBackPress()
                      // navigate('/search?'+buildUrlParams())
                    }}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M2.87 7.75082L4.84 9.72082C4.91369 9.78949 4.97279 9.87229 5.01378 9.96428C5.05477 10.0563 5.07681 10.1556 5.07859 10.2563C5.08037 10.357 5.06184 10.457 5.02412 10.5504C4.9864 10.6438 4.93026 10.7286 4.85904 10.7999C4.78782 10.8711 4.70299 10.9272 4.6096 10.9649C4.51621 11.0027 4.41618 11.0212 4.31548 11.0194C4.21477 11.0176 4.11546 10.9956 4.02346 10.9546C3.93146 10.9136 3.84866 10.8545 3.78 10.7808L0.53 7.53082L0 7.00082L0.53 6.47082L3.78 3.22082C3.92217 3.08834 4.11022 3.01622 4.30452 3.01965C4.49882 3.02308 4.68421 3.10179 4.82162 3.2392C4.95903 3.37661 5.03775 3.562 5.04117 3.7563C5.0446 3.9506 4.97248 4.13865 4.84 4.28082L2.87 6.25082H12.75C13.612 6.25082 14.4386 6.59323 15.0481 7.20273C15.6576 7.81222 16 8.63887 16 9.50082C16 10.3628 15.6576 11.1894 15.0481 11.7989C14.4386 12.4084 13.612 12.7508 12.75 12.7508H10.75C10.5511 12.7508 10.3603 12.6718 10.2197 12.5312C10.079 12.3905 10 12.1997 10 12.0008C10 11.8019 10.079 11.6111 10.2197 11.4705C10.3603 11.3298 10.5511 11.2508 10.75 11.2508H12.75C13.2141 11.2508 13.6592 11.0664 13.9874 10.7383C14.3156 10.4101 14.5 9.96495 14.5 9.50082C14.5 9.03669 14.3156 8.59157 13.9874 8.26339C13.6592 7.9352 13.2141 7.75082 12.75 7.75082H2.87Z" fill="white" />
                      </svg>
                    </button>
                  )}
                  {/* <button className="filter-btn"
                    onClick={() => {
                      setFilterDrop(true)
                    }}
                  >
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.5715 10.5941L17.4266 4.72014C17.7929 4.35183 18 3.84877 18 3.32376V1.60099C18 0.520023 17.1423 0 16.0844 0H1.91556C0.857655 0 0 0.520023 0 1.60099V3.3547C0 3.85177 0.18462 4.33087 0.517719 4.69419L5.89711 10.5632C5.9987 10.674 6.14034 10.7368 6.28979 10.7378L11.1915 10.7518C11.3332 10.7528 11.4699 10.6969 11.5715 10.5941Z" fill="#2EABE3" />
                      <path opacity="0.4" d="M6.05469 10.6855V17.2901C6.05469 17.5307 6.17581 17.7573 6.37411 17.887C6.48742 17.9619 6.61832 17.9998 6.74921 17.9998C6.84787 17.9998 6.94653 17.9789 7.03835 17.9369L11.0043 16.0884C11.2524 15.9736 11.4116 15.7211 11.4116 15.4426V10.6855H6.05469Z" fill="#2EABE3" />
                    </svg>
                  </button>
                  {filterDrop &&
                    <>



                      <div className="fixed top-0 left-0 w-full h-screen " onClick={() => {
                        setFilterDrop(false)
                      }}></div>
                      <div className="filters-drop duration-150 rounded-[10px]  flex flex-col gap-1 absolute left-0 bg-[#ffffff] min-w-max px-4 py-2 shadow-lg" style={{ zIndex: '999999999' }}>
                        <FilterCheckBoxes Label="filter 1" />
                        <FilterCheckBoxes Label="Nearest" />
                        <FilterCheckBoxes Label="Doorest" />
                        <div className="flex items-center gap-2 mt-4 ">
                          <button
                            style={{ height: 'auto', borderRadius: '5px', background: '#707070', color: 'white', padding: '3px' }}
                            type="button">Clear</button>
                          <button
                            style={{ height: 'auto', borderRadius: '5px', color: 'white', padding: '3px' }}
                            type="button">Apply</button>
                        </div>
                      </div>
                    </>
                  } */}
                </div>
                <button>
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M20.9984 21.0004L16.6484 16.6504" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                </button>
              </div>
            </div>
          </div>
        ) : (

          <div className={`search-field-holder flex-col select-airport ${!selectedAirport && checkInDate && checkoutDate && !loadAirport ? '  border-[1px] jiggle border-[red]': ''} lg:gap-0 gap-4`}>
            <div className="relative w-full">
              {loadAirport ? <Spinner /> :
                <>
                  <Select
                    options={airports}
                    onInputChange={(query) => {
                      if (query?.length > 0) setShowDropdownMenu(true)
                      else setShowDropdownMenu(false)
                    }}
                    // menuIsOpen={showDropdownMenu}
                    isDisabled={disable_airport}
                    onMenuClose={() => {
                      setShowDropdownMenu(false)
                    }}
                    value={selectedAirport}
                    onChange={(selected) => setSelectedAirport(selected)}
                    placeholder={selectedAirport ? selectedAirport?.name : "Search airport..."}
                    className="airport-dropdown"
                  />

                  {!selectedAirport && checkInDate && checkoutDate && !loadAirport ?
                    <p className="text-[#ee4c4c] text-[12px] please-select">Please select Airport</p>
                    : ''}
                </>
              }

            </div>
            <div className="checkin-box-holder">
              <div className="checkin-box">

                <CheckInComponent />
              </div>
              <div className="checkin-box">

                <ChecoutComponent />
              </div>
            </div>
            <button
              className="w-full search-btn-mobile"
              onClick={handleSearchClick} disabled={!selectedAirport || !checkInDate || !checkoutDate}>
              <span>Search</span>

            </button>
          </div>

        )}


        {showDataPicker && (

          <DateTimePickerModal
            selectedField={timePikerType}
            onClosePaymentModal={() => {
              scrollToTop()
              setShowDatePicker(false)
            }}

            onAllSelected={(date) => {

              onDateChanged && onDateChanged();
              setCheckInDate(dayjs(date.checkIn, 'YYYY-MM-DD hh:mm A'));
              setCheckoutData(dayjs(date.checkOut, 'YYYY-MM-DD hh:mm A'));
              dispatch(reservationActions.updateCheckin(date.checkIn));
              dispatch(reservationActions.updateCheckout(date.checkOut));
              dispatch(reservationActions.updateDays(getDaysBetweenDates(date.checkIn, date.checkOut)));

            }}
            setFieldFocus={(fieldName) => {
              setTimePickerType(fieldName)
            }}
            onCheckinSelected={(checkIn) => {
              // alert(checkIn)
              setCheckInDate(dayjs(checkIn, 'YYYY-MM-DD hh:mm A'));
              setTimePickerType('checkout')
            }}
            onCheckoutSelected={(checkout) => {
              // alert(checkIn)
              setCheckoutData(dayjs(checkout, 'YYYY-MM-DD hh:mm A'));
              setTimePickerType('checkout')
            }}
          />

        )}

      </div>
    </>
  );
};

export default SearchBox;
